<template>
	<div class="w-full p-6">
		<div class="flex justify-between cursor-pointer"
			@click="changeCollapse">
			<div class="flex">
				<span class="mr-6 text-2xl icon-check-circle text-gray-dark"></span>
				<div class="">
					<h4 class="text-base font-semibold text-gray-dark">SCORING INFORMATION <span class="text-error">*</span></h4>
					<p v-if="errorScoringInformationBobotTotal.value"
						class="mt-1 text-xs text-error">{{ errorScoringInformationBobotTotal.message }}</p>
				</div>
			</div>
			<div>
				<p :class="collapse.scoringTeknis ? 'rotate-180' : 'rotate-0'"
					class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
			</div>
		</div>
		<div v-show="collapse.scoringTeknis"
			class="grid w-full grid-cols-1 gap-6 my-6 lg:grid-cols-2">
			<Card v-for="(score, i) in scoringTeknisCriteria"
				:key="i">
				<div class="flex justify-between h-16">
					<div class="flex-wrap">
						<h5 class="text-gray">{{ score.name }} {{i == 3? '(min. 25)': ''}} <span class="text-error">*</span></h5>
						<p v-if="errorScoringInformationBobotPrice?.value && i === 3" class="mt-1 text-xs text-error">{{errorScoringInformationBobotPrice?.message}}</p>
					</div>
					<div class="">
						<ValidationProvider
							name="bobot"
							:vid="`bobot${i}`"
							rules="min_one:1"
							v-slot="{ errors }">
							<div class="flex items-center justify-between">
								<div class="flex items-center justify-center text-white rounded-lg w-9 h-9"
									:class="`${score.scoreTotal > +score.bobot ? 'bg-error' : score.scoreTotal < score.bobot ? 'bg-warning' : 'bg-success'}`">
									<p>{{ score?.scoreTotal }}</p>
								</div>
								<p class="text-gray">&nbsp; / Bobot: <span class="text-error">*</span> &nbsp;</p>
								<div v-if="i !== 3" class="w-12">
									<InputSolutip
										type="text"
										size="tiny"
										align="center"
										@keypress="validationNumber"
										@input="inputBobotTotal"
										v-model="score.bobot"
										placeholder="0"
										:error="score.errorScoreTotal.value || !!errors[0] || errorScoringInformationBobotTotal?.value"
									>
									</InputSolutip>

								</div>
								<div v-else class="w-12">
									<InputSolutip
										type="text"
										size="tiny"
										align="center"
										@keypress="validationNumber"
										@focus="changeHideCheckErrorTotalScore(i)"
										@input="inputBobotTotal"
										v-model="score.bobot" 
										placeholder="0"
										:error="score.errorScoreTotal.value || errorScoringInformationBobotTotal?.value || errorScoringInformationBobotPrice?.value"
									>
									</InputSolutip>
								</div>
							</div>
							
							<p v-if="errors[0]"
								class="mt-1 text-xs text-right text-error">{{ errors[0] }}</p>
							<p v-else-if="score.errorScoreTotal.value && i !== 3"
								class="mt-1 text-xs text-error">{{ score.errorScoreTotal.message }}</p>
							<p v-else-if="score.errorScoreTotal.value && i === 3 && !hideCheckErrorTotalScore"
								class="mt-1 text-xs text-error">{{ score.errorScoreTotal.message }}</p>
						</ValidationProvider>
					</div>
				</div>
				<div v-for="(e, j) in score.rfpScoringTeknis"
					:key="j"
					class="mb-4">
					<div class="flex justify-between">
						<div class="w-4/5 mr-3.5">
              <ValidationProvider name="Sub-criteria"
                                  :vid="`Sub-criteria #${j + 1}`"
                                  :rules="{ max: 500 }"
                                  v-slot="{ errors }">
                <TextareaGista :label="`Sub-criteria #${j + 1}`"
                               placeholder="Masukan sub-criteria"
                               v-model="e.name"
                               @input="inputReason($event, i)"
                               rows="1"
                               :error="!!errors[0] ">
                  <template #message>
                    <p>{{ errors[0]  }}</p>
                  </template>
                </TextareaGista>
              </ValidationProvider>
						</div>
						<div class="flex">
							<ValidationProvider name="bobot"
								:vid="`bobot${i}${j}`"	
								:rules="`min_one:1`"
								v-slot="{ errors }">
								<div class="w-full">
									<InputSolutip type="text"
										label="score"
										size="large"
										:mandatory="j === 0"
										@keypress="validationNumber"
										@focus="changeHideCheckErrorTotalScore(i)"
										@input="inputBobotScore($event, i, j)"
										placeholder="0"
										v-model="e.bobot"
										:error="!!errors[0] || e.errorScore?.value || (j === 0 && score.errorScoreTotal.value && !hideCheckErrorTotalScore)">
										<template #message>
											<p>{{ errors[0] || e.errorScore?.message }}</p>
										</template>
									</InputSolutip>
								</div>
							</ValidationProvider>
							<div v-if="score.rfpScoringTeknis.length > 1" class="flex items-center">
								<p class="ml-3 text-xl cursor-pointer icon-x-circle text-error" @click="removeSubCriteria(i, j)" ></p>
							</div>
						</div>
					</div>
				</div>
				<div class="flex justify-center w-full">
					<ButtonGista type="primary"
						color="tertiary"
						expanded
						size="small"
						@click="addSubCriteria(i)">
						Add Sub-Criteria
					</ButtonGista>
				</div>
			</Card>
		</div>

	</div>
</template>

<script>
import Card from '@/core/components/custom/Card.vue'
import InputSolutip from '@/core/components/custom/Input.vue'
import { MESSAGE_CANNOT_SAME_VALUE, MESSAGE_EMPTY_FIELD, MESSAGE_TOTAL_SCORE } from '@/core/constant/errorMessage.js'

export default {
	name: "ScoringInformation",
	data() {
		return {
			key: ['vendorData', 'solution', 'support', 'price'],
		}
	},
	computed: {
		collapse() {
			return this.$store.state.rfp.collapse
		},
		hideCheckErrorTotalScore: {
			get() {
				return this.$store.state.errorRfp.hideCheckErrorTotalScore
			},
			set(value) {
				this.$store.commit('errorRfp/SET_HIDE_CHECK_ERROR_TOTAL_SCORE', value)
			}
		},
		scoringTeknisCriteria: {
			get() {
				return this.$store.state.rfp.scoringTeknisCriteria
			},
			set(value) {
				this.$store.commit('rfp/SET_SCORING_TEKNIS_CRITERIA', value)
			}
		},
		errorScoringInformationBobotPrice: {
			get() {
				return this.$store.state.errorRfp.errorScoringInformationBobotPrice
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_SCORING_INFORMATION_BOBOT_PRICE', value)
			}
		},
		errorScoringInformationBobotTotal: {
			get() {
				return this.$store.state.errorRfp.errorScoringInformationBobotTotal
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_SCORING_INFORMATION_BOBOT_TOTAL', value)
			}
		},
	},
	methods: {
		addSubCriteria(index) {
			this.scoringTeknisCriteria[index].rfpScoringTeknis = [
				...this.scoringTeknisCriteria[index].rfpScoringTeknis, {
					bobot: '',
					name: "",
					rfpScoringTeknisId: 0,
					sequence: this.scoringTeknisCriteria[index].sequence,
					scoringTeknisCriteriaId: index+1,
					isDeleted: false,
					error: { value: false, message: '' },
					errorSubField: { value: false, message: '' },
					errorScore: { value: false, message: '' }
				}]
		},
		removeSubCriteria(indexScoring, indexSubCriteria) {
			this.scoringTeknisCriteria[indexScoring].rfpScoringTeknis = this.scoringTeknisCriteria[indexScoring].rfpScoringTeknis.filter((e, i) => i !== indexSubCriteria)
			this.inputBobotTotal()
		},
		validationNumber(e) {
			let keyCode = e.keyCode;
			if (keyCode < 48 || keyCode > 57) {
				e.preventDefault();
			}
		},
		changeHideCheckErrorTotalScore(i) {
			if (i === 3) {
				this.hideCheckErrorTotalScore = false
			}
		},
		inputSubCriteria(e, i, j) {
			if (!e && j === 0) {
				this.scoringTeknisCriteria[i].rfpScoringTeknis[j].errorSubField = { value: true, message: MESSAGE_EMPTY_FIELD }
			} else {
				this.scoringTeknisCriteria[i].rfpScoringTeknis[j].errorSubField = { value: false, message: '' }
			}
		},
		checkErrorSubmit() {
			this.checkError()
			this.checkBobotTotal()
			this.checkErrorDuplicateValue(0)
			this.checkErrorDuplicateValue(1)
			this.checkErrorDuplicateValue(2)
			this.checkErrorDuplicateValue(3)
		},
		inputBobotTotal() {
			this.checkError()
			this.checkBobotTotal()
		},
		inputBobotScore(e, i, j) {
			if (!e && this.scoringTeknisCriteria[i].rfpScoringTeknis[j].name) {
				this.scoringTeknisCriteria[i].rfpScoringTeknis[j].errorScore = { value: true, message: MESSAGE_EMPTY_FIELD }
			} else {
				this.scoringTeknisCriteria[i].rfpScoringTeknis[j].errorScore = { value: false, message: '' }
			}
			this.checkError()
			this.checkBobotTotal()
		},
		blurSubCriteria(index) {
			this.checkErrorDuplicateValue(index)
		},
		checkError() {
			this.scoringTeknisCriteria.forEach((e, i) => {
				this.scoringTeknisCriteria[i].scoreTotal = e.rfpScoringTeknis.reduce((a, b) => +a + +b.bobot, 0)
				if (+this.scoringTeknisCriteria[i].scoreTotal !== +this.scoringTeknisCriteria[i].bobot) {
					e.errorScoreTotal = {value: true, message: MESSAGE_TOTAL_SCORE}
				} else if(e.bobot) {
					e.errorScoreTotal.value = false
				}
				if (i === 3) {
					if (+this.scoringTeknisCriteria[3].bobot < 25) {
						this.errorScoringInformationBobotPrice.value = true
					} else {
						this.errorScoringInformationBobotPrice.value = false
					}
				}
				
			})
		},
		checkBobotTotal() {
			const bobotTotal = this.scoringTeknisCriteria.reduce((a,b) => +a + +b.bobot, 0)
			if (bobotTotal !== 100) {
				this.errorScoringInformationBobotTotal.value = true
			} else {
				this.errorScoringInformationBobotTotal.value = false
			}
		},
		checkErrorDuplicateValue(index) {
			const uniqueDuplicateElements = this.uniqueDuplicateElements(index)
			if (uniqueDuplicateElements.length > 0) {
				this.setErrorMessageDuplicateValue(uniqueDuplicateElements, index)
			} else {
				this.scoringTeknisCriteria[index].rfpScoringTeknis = this.scoringTeknisCriteria[index].rfpScoringTeknis.map(e => ({ ...e, error: { value: false, message: '' } }))
			}
		},
		uniqueDuplicateElements(index) {
			const duplicateElements = this.scoringTeknisCriteria[index].rfpScoringTeknis.filter((item, i) => {
					return  this.scoringTeknisCriteria[index].rfpScoringTeknis.map(el => el.name).indexOf(item.name) !== i && item.name
				})
			return duplicateElements.filter((item, i) => duplicateElements.map(el => el.name).indexOf(item.name) === i)
		},
		setErrorMessageDuplicateValue(uniqueDuplicateElements, index) {
			let arrayFlag = []
			uniqueDuplicateElements.forEach((el) => {
				this.scoringTeknisCriteria[index].rfpScoringTeknis.forEach((item, i) => {
					if (el.name === item.name || arrayFlag.includes(i)) {
						item.error = { value: true, message: MESSAGE_CANNOT_SAME_VALUE }
						arrayFlag.push(i)
					} else {
						item.error = { value: false, message: '' }
					}
				})
			})
		},
		changeCollapse() {
			this.$emit('collapse')
		},

	},
	components: {
		Card,
		InputSolutip,
	}
}
</script>
